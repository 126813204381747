<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" />
        <filter-province class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" />
        <filter-area class="mt-2" v-model:value="area" v-model:provinsi="provinsi" v-model:region="region"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota class="mr-2 mt-2" v-model:value="kabupaten" v-model:area="area" />
        <filter-distributor
          class="mr-2 mt-2"
          v-model:value="distributor"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"
        ></filter-distributor>
        <filter-gudang
          class="mr-2 mt-2"
          v-model:value="gudang"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:distributor="distributor"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <a-date-picker
          class="mr-2 mt-2"
          v-model:value="start_date"
          placeholder="Dari Tanggal"
          style="width : 300px"
          :disabled-date="disabledStartDate"
        />
        <a-date-picker
          v-model:value="end_date"
          placeholder="Sampai Tanggal"
          class="mr-2"
          style="width : 300px"
          :disabled-date="disabledEndDate"
        />
        <a-button class="mr-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>

        <a-button
          class="mt-2"
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Download ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <!-- <e-chart title="Performance Gudang" /> -->
              <e-chart title="Performance Gudang" vif="!isFetching" :response="response" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <!-- <e-chart title="Transaksi Gudang" /> -->
              <t-chart title="Transaksi Gudang" vif="!isFetching"  :response="response"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  reactive,
  inject,
  onMounted,
  computed,
  nextTick,
  toRefs,
  onBeforeMount,
  provide,
  watch,
} from 'vue'
import Loading from '@/components/Loading'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterDistributor from '@/components/filter/FilterDistributor'
import qs from 'qs'

import { useDisabledDate } from '@/composables/DisabledDate'
import moment from 'moment'

const acolumns = [
  {
    title: 'Tanggal',
    dataIndex: 'date',
  },
  {
    title: 'Kode Gudang',
    dataIndex: 'gudang_code',
  },
  {
    title: 'Nama Gudang',
    dataIndex: 'gudang_name',
  },
  {
    title: 'Sell In',
    dataIndex: 'sell_in',
  },
  {
    title: 'Sell Out',
    dataIndex: 'sell_out',
  },
  {
    title: 'Sell Out Id',
    dataIndex: 'sell_out_id',
    title_xls: 'Sell Out Id',
  },
  {
    title: 'Sell Out Non Id',
    dataIndex: 'total',
    title_xls: 'Sell Out Non Id',
  },
  {
    title: 'Stock',
    dataIndex: 'stock',
  },
  {
    title: 'Nama Regional',
    dataIndex: 'regional',
  },
  {
    title: 'Nama Provinsi',
    dataIndex: 'provinsi',
  },
  {
    title: 'Nama Kabupaten Kota',
    dataIndex: 'kabupaten',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor',
  },
]
export default defineComponent({
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterGudang,
    EChart: defineAsyncComponent({
      loader: () => import('./EChart.vue' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
    }),
    TChart: defineAsyncComponent({
      loader: () => import('./TChart.vue' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
    }),
  },
  setup() {
    const modal1Visible = ref(false)
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }
    const data = ref([])
    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const distributor = ref([])
    const area = ref([])
    const gudang = ref([])
    const errorMessage = ref(null)
    const columns = ref([])
    const {
      start_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      startDate,
      endDate,
    } = useDisabledDate()

    const response = ref()
    const params = ref({})

    const fetchData = () => {
      const _params = {
        // page: page.value,
        // 'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        gudang: gudang.value,
        // brand: brands.value,
        distributor: distributor.value,
        start_date: startDate.value,
        end_date: endDate.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report/grafik-gudang', {
          params: _params,
        })
        .then(({ data }) => {
          response.value = data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    columns.value = acolumns

     const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report/performance-gudang', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Performance-Gudang_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const state = reactive({
      loading: false,
      isFetching: false,
      isDownloading: false,
    })
    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const handleOk = () => {
      loading.value = true
      setTimeout(() => {
        loading.value = false
        visible.value = false
      }, 2000)
    }

    const handleCancel = () => {
      visible.value = false
    }

    return {
      modal1Visible,
      setModal1Visible,
      handleOk,
      handleCancel,
      q,
      searchText,
      searchInput,
      data,
      current1,
      region,
      provinsi,
      kabupaten,
      area,
      gudang,
      state,
      fetchXlsx,
      distributor,
      fetchData,
      ...toRefs(state),
      start_date,
      end_date,
      search,
      disabledStartDate,
      disabledEndDate,
      errorMessage,
      columns,
      response,
    }
  },
})
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
